import { template as template_687a40e3ee2e4a64be78935fa70fa5e6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_687a40e3ee2e4a64be78935fa70fa5e6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
