import { template as template_b0f9deb3da7f455aa0f49a6f6b1c4f10 } from "@ember/template-compiler";
const FKText = template_b0f9deb3da7f455aa0f49a6f6b1c4f10(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
